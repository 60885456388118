.mbtn-filter {
    font-weight: bold;
    font-size: 0.8em;
    text-align: left;
    padding:0 4px;
}

.mbtn-filter .icon {
    margin-top:0;
}

.mbtn-readmore {
    font-weight: bold;
    font-size: 0.8em;
    padding:0 4px;
}

.r3d-icon {
    width: 20px;
    margin: 1px;
}

.r3d-icon-big {
    width: 23px;
    margin: 3px 3px 0 0;
}

/* Assistive text */
.assistive-text {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}
#access a.assistive-text:active,
#access a.assistive-text:focus {
    background: #eee;
    border-bottom: 1px solid #ddd;
    color: #1982d1;
    clip: auto !important;
    font-size: 12px;
    position: absolute;
    text-decoration: underline;
    top: 0;
    left: 7.6%;
}

/* =Header ----------------------------------------------- */
#branding {
    border-top: 2px solid #bbb;
    padding-bottom: 10px;
    position: relative;
    z-index: 9999;
}
#site-title {
    margin-right: 270px;
    padding: 3.65625em 0 0;
}
#site-title a {
    color: #111;
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
    text-decoration: none;
}
#site-title a:hover,
#site-title a:focus,
#site-title a:active {
    color: #1982d1;
}
#site-description {
    color: #7a7a7a;
    font-size: 14px;
    margin: 0 270px 3.65625em 0;
}
#branding img {
    height: auto;
    margin-bottom: -7px;
    width: 100%;
}

/* =Menu
-------------------------------------------------------------- */
#access div {
    margin: 0 7.6%;
}

#access {
    background: #222; /* Show a solid color for older browsers */
    background: -moz-linear-gradient(#252525, #0a0a0a);
    background: -o-linear-gradient(#252525, #0a0a0a);
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#252525), to(#0a0a0a)); /* older webkit syntax */
    background: -webkit-linear-gradient(#252525, #0a0a0a);
    -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px;
    clear: both;
    display: block;
    float: left;
    margin: 0 auto 6px;
    width: 100%;
}
#access ul {
    font-size: 13px;
    list-style: none outside none;
    margin: 0 0 0 -0.8125em;
    padding-left: 0;
}
#access li {
    float: left;
    position: relative;
}
#access a {
    color: #EEEEEE;
    display: block;
    line-height: 3.333em;
    padding: 0 1.2125em;
    text-decoration: none;
}
#access ul ul {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    display: none;
    float: left;
    left: 0;
    margin: 0;
    position: absolute;
    top: 3.333em;
    width: 188px;
    z-index: 99999;
}
#access ul ul ul {
    left: 100%;
    top: 0;
}
#access ul ul a {
    background: none repeat scroll 0 0 #F9F9F9;
    border-bottom: 1px dotted #DDDDDD;
    color: #444444;
    font-size: 13px;
    font-weight: normal;
    height: auto;
    line-height: 1.4em;
    padding: 10px;
    width: 168px;
}
#access li:hover > a, #access ul ul *:hover > a, #access a:focus {
    background: none repeat scroll 0 0 #EFEFEF;
}
#access li:hover > a, #access a:focus {
    background: linear-gradient(#F9F9F9, #E5E5E5) repeat scroll 0 0 transparent;
    color: #373737;
}
#access ul li:hover > ul {
    display: block;
}
#access .current-menu-item > a, #access .current-menu-ancestor > a, #access .current_page_item > a, #access .current_page_ancestor > a {
    font-weight: bold;
}

/* Search Form */
#branding #searchform {
    position: absolute;
    top: 3.8em;
    right: 7.6%;
    text-align: right;
}
#branding #searchform div {
    margin: 0;
}
#branding #s {
    float: right;
    -webkit-transition-duration: 400ms;
    -webkit-transition-property: width, background;
    -webkit-transition-timing-function: ease;
    -moz-transition-duration: 400ms;
    -moz-transition-property: width, background;
    -moz-transition-timing-function: ease;
    -o-transition-duration: 400ms;
    -o-transition-property: width, background;
    -o-transition-timing-function: ease;
    width: 72px;
}
#branding #s:focus {
    background-color: #f9f9f9;
    width: 196px;
}
#branding #searchsubmit {
    display: none;
}
#branding .only-search #searchform {
    top: 5px;
    z-index: 1;
}
#branding .only-search #s {
    background-color: #666;
    border-color: #000;
    color: #222;
}
#branding .only-search #s,
#branding .only-search #s:focus {
    width: 85%;
}
#branding .only-search #s:focus {
    background-color: #bbb;
}
#branding .with-image #searchform {
    top: auto;
    bottom: -27px;
    max-width: 195px;
}
#branding .only-search + #access div {
    padding-right: 205px;
}

#filters-container { padding: 3px 15px; }
#filters-container #show-filters-btn {
    padding: 2px;
    color: #666;
}
#filters-container #collapse-icon { margin-left: 3px; }
#filters-container .number-of-results {
    font-size:0.9em;
    color:#666;
    float: right;
}
#filters-container .number-of-results small {
    font-size:0.8em;
    font-weight: bold;
    color:#999;
}
#filters-container #filters-collapsible .navbar { margin-bottom: 10px }

#results-container {
    position: relative;
}

#results-container #loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    opacity: 0.8;
    background-color: #fff;
    z-index: 99;
    text-align: center;
}
#results-container .loading-image {
    top: 30px;
    left: 400px;
    position: absolute;
    display: block;
    z-index: 100;
}

#results-list {
    padding: 10px 0 0 0;
}


#results-list .result-element-container {
    border: solid 1px #ddd;
    -moz-border-radius: 12px 4px;
    -webkit-border-radius: 12px 4px;
    -khtml-border-radius: 12px 4px;
    border-radius: 12px 4px;

    padding: 0 10px;
    margin: 10px 0 20px 0;
}
#results-list .result-element-container .result-element-title {
    width: 700px;
}
#results-list .result-element-container .result-element-icons {
    width: 146px;
}
#results-list .result-element-container .alert {
    padding: 5px;
    margin: 0 0 5px 0;
}
#results-list .result-element-container .result-element-properties-container {

}
#results-list .result-element-container .result-element-properties-container .label {
    font-size: 0.8em;
    margin: 2px 4px 2px 0;
    padding: 1px 4px;
    display: block;
    float: left;
}
#results-list .result-element-container .result-element-properties-container .label-inactive {
    background-color: #efefef;
    border: solid 1px #bbb;
    color: #777;
    padding: 0 3px;
    text-shadow: none;
}
#results-list .result-element-container .result-element-properties-title {
    font-size: 0.8em;
    font-weight: bold;
    color: #555;
    text-align: right;
    vertical-align: text-top;
    padding: 3px;
    white-space: nowrap;
}
#results-list .result-element-container .result-element-properties {
    padding: 3px;
}
#results-list .result-element-container .result-element-description {
    font-size: 0.9em;
    padding: 5px 0;
}

.pagination-bar .pagination-infos {
    font-size:0.9em;
    color:#666;
    margin: 3px 0 0 0;
    line-height: 16px
}
.pagination-bar .pagination-infos .btn {    
    height: 21px;
    margin-bottom: 2px;
    border-color: #aaa;
}
.pagination-bar .pagination-infos .btn .caret {
    margin-top: 8px;
}
.pagination-bar .pagination-infos .btn .sortOrderText {
    display: inline-block;
    font-size: 13px;
    margin-right: 3px;
}

.pagination-bar .pagination-infos ul.dropdown-menu { min-width: 0; }

.pagination-bar .pagination-infos .dropdown-menu li a {
    padding: 2px 7px;
    line-height: 16px;
}

.pagination-bar .pagination-infos .dropdown-menu li a small{
    font-size: 0.8em;
    color: #999;
}
.pagination-bar .pagination-infos .dropdown-menu li a:hover small{ color: #fff; }
.pagination-bar .pagination-infos .dropdown-menu li.disabled a:hover small{ color: #999; }


.pagination-bar .pagination {
    margin:0 4px 0 0;
    line-height: 14px;
    font-weight: bold;
}

.filter-select {
    padding:3px;
    height: auto;
}

.filter-icon {
    vertical-align: middle;        
}

.float-left{
    float: left;
}
.float-right{
    float: right;
}

/** New CSS Property for buttons used in pagination **/
.pagination-btn{
    float: left;
    padding: 4px 12px;
    line-height: 20px;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    
}

.pagination-btn-mini{
    padding: 1px 7px;
    font-size: 11.9px;
}

.btn-group.open .dropdown-toggle {
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);
}

.pagination-btn-group{
    position:relative;
    display:inline-block;
    vertical-align: middle;
}

.pagination-btn-group>.dropdown-toggle {

  /**
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;**/
}

.pagination-btn-group.open .dropdown-toggle {
    background-color: #f5f5f5;
  /**
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 2px 4px rgba(0,0,0,.15),0 1px 2px rgba(0,0,0,.05);**/
}


.pagination-dropdown-menu{
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
}

.pagination-container{
    width: 100%;
    text-align: center;
}